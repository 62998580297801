exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-help-page-tsx-content-file-path-opt-render-project-src-website-src-content-help-0-index-mdx": () => import("./../../../src/templates/help-page.tsx?__contentFilePath=/opt/render/project/src/website/src/content/help/0-index.mdx" /* webpackChunkName: "component---src-templates-help-page-tsx-content-file-path-opt-render-project-src-website-src-content-help-0-index-mdx" */),
  "component---src-templates-help-page-tsx-content-file-path-opt-render-project-src-website-src-content-help-getting-started-0-how-it-works-index-mdx": () => import("./../../../src/templates/help-page.tsx?__contentFilePath=/opt/render/project/src/website/src/content/help/getting-started/0-how-it-works/index.mdx" /* webpackChunkName: "component---src-templates-help-page-tsx-content-file-path-opt-render-project-src-website-src-content-help-getting-started-0-how-it-works-index-mdx" */),
  "component---src-templates-help-page-tsx-content-file-path-opt-render-project-src-website-src-content-help-getting-started-1-creating-a-form-index-mdx": () => import("./../../../src/templates/help-page.tsx?__contentFilePath=/opt/render/project/src/website/src/content/help/getting-started/1-creating-a-form/index.mdx" /* webpackChunkName: "component---src-templates-help-page-tsx-content-file-path-opt-render-project-src-website-src-content-help-getting-started-1-creating-a-form-index-mdx" */),
  "component---src-templates-help-page-tsx-content-file-path-opt-render-project-src-website-src-content-help-support-0-contact-us-mdx": () => import("./../../../src/templates/help-page.tsx?__contentFilePath=/opt/render/project/src/website/src/content/help/support/0-contact-us.mdx" /* webpackChunkName: "component---src-templates-help-page-tsx-content-file-path-opt-render-project-src-website-src-content-help-support-0-contact-us-mdx" */),
  "component---src-templates-us-page-tsx-content-file-path-opt-render-project-src-website-src-content-us-policies-0-terms-and-conditions-mdx": () => import("./../../../src/templates/us-page.tsx?__contentFilePath=/opt/render/project/src/website/src/content/us/policies/0-terms-and-conditions.mdx" /* webpackChunkName: "component---src-templates-us-page-tsx-content-file-path-opt-render-project-src-website-src-content-us-policies-0-terms-and-conditions-mdx" */),
  "component---src-templates-us-page-tsx-content-file-path-opt-render-project-src-website-src-content-us-policies-1-privacy-policy-mdx": () => import("./../../../src/templates/us-page.tsx?__contentFilePath=/opt/render/project/src/website/src/content/us/policies/1-privacy-policy.mdx" /* webpackChunkName: "component---src-templates-us-page-tsx-content-file-path-opt-render-project-src-website-src-content-us-policies-1-privacy-policy-mdx" */),
  "component---src-templates-us-page-tsx-content-file-path-opt-render-project-src-website-src-content-us-policies-2-cookies-policy-mdx": () => import("./../../../src/templates/us-page.tsx?__contentFilePath=/opt/render/project/src/website/src/content/us/policies/2-cookies-policy.mdx" /* webpackChunkName: "component---src-templates-us-page-tsx-content-file-path-opt-render-project-src-website-src-content-us-policies-2-cookies-policy-mdx" */)
}

